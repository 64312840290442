@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import "primeicons/primeicons.css";

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #fff;
  font-size: 12px;
  line-height: 14.25px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.p-inputtext {
  height: 33px;
  border-radius: 3px !important;
  font-size: 0.875rem !important;
  padding: 0.5rem 0.5rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #0066FF !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: white !important;
}

.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
  color: #0066FF !important;
}

.p-paginator .p-paginator-next,
.p-paginator-prev {
  color: #0066FF !important;
}

.p-calendar .p-button {
  background: #FFDAB9 !important;
  border: 1px solid rgb(226, 221, 220) !important;
  height: 25px;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator-next,
.p-paginator .p-paginator-prev {
  border: 1px solid #e9e5e4 !important;
  border-radius: 0 !important;
  margin: 0px !important;
  height: 26px !important;
  width: 10px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #1877F2 !important;
}

.p-paginator {
  padding: 0px !important;
  margin-left: 7px !important;
}

.p-inputtext {
  height: 25px !important;
  border-radius: 3px !important;
  font-size: 0.875rem !important;
  padding: 0.5rem 0.5rem !important;
}

.p-inputtextarea {
  height: 55px !important;
  width: 185% !important;
  border-radius: 3px !important;
  font-size: 0.875rem !important;
  padding: 0.5rem 0.5rem !important;
}